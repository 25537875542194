<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
          class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar />
        </div>
        <div v-if="loaded" class="column maincolumn examContainer">
          <div class="is-full-touch is-full-mobile" v-if="activeSub">
            <div class="toolbar">
              <span @click="setCategory('active')">Active Exams</span>
              <span v-if="admin" @click="setCategory('archived')"
                >Archived Exams</span
              >
              <div v-if="admin" class="field is-grouped">
                <router-link
                  :to="{ name: 'EducationExamsAddExam' }"
                  class="button is-success"
                  >Create Exam
                </router-link>
              </div>
            </div>
          </div>
          <div class="is-flex is-justify-content-space-between block"></div>
          <div class="block">
            <div class="exam-cont">
              <p v-if="!filteredExams.length">
                No active exams at the moment! Come back later.
              </p>
              <div
                v-for="(exam, i) in filteredExams"
                :key="exam.index"
                class="exam"
              >
                <p class="exam-title">Exam {{ i + 1 }}</p>
                <div class="exam-body is-flex-desktop is-flex-tablet">
                  <div @click="openExam(exam)" class="exam-img old">
                    <img class="exam-wave" src="@/assets/lessons/wave.svg" />
                    <span class="exam-img-title">{{ exam.title }}</span>
                  </div>
                  <div class="exam-txt">
                    <p>{{ exam.description }}</p>
                    <span @click="openExam(exam)">Start now >></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { auth, db } from "@/firebase";

export default {
  name: "EducationExams",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      admin: false,
      loaded: false,
      category: "active",
      exams: [],
    };
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
    this.$bind(
      "user",
      db.collection("users").doc(this.$store.state.userProfile.uid)
    ).then((user) => {
      // user will point to the same property declared in data:
      // this.user === user
      this.loaded = true;
      // console.log(user)
    });
  },
  firestore: {
    exams: db.collection("exams"),
  },
  computed: {
    activeSub() {
      return this.user.subscription_end_date?.toDate() > new Date();
    },
    filteredExams() {
      return this.exams.filter((exam) => exam.category === this.category);
    },
  },
  methods: {
    openExam(exam) {
      this.$router.push({
        name: "EducationExamsExam",
        params: {
          exam: exam.id,
        },
      });
    },
    setCategory(category) {
      this.category = category;
    },
  },
};
</script>

<style scoped lang="scss">
.maincolumn {
  padding: 78px 78px 0 78px;
}
.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    transform: scale(1.02, 1.02);
    &::after {
      opacity: 1;
    }
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.has-text-centered.block {
  flex: 1;
}
.toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 75px;
  .dividerbar {
    width: 1px;
    height: 23px;
    background: var(--text-primary-color);
  }
  span {
    &:hover {
      text-decoration-line: underline;
      color: var(--text-secondary-color);
    }
    cursor: pointer;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: var(--text-tertiary-color);
  }
}

.exam-cont {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.exam {
  //display: flex;
  .exam-title {
    margin-bottom: 10px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    color: var(--text-primary-color);
  }
  .exam-body {
    gap: 33px;
    //.exam-img {
    //  img {
    //    width: auto;
    //    height: auto;
    //    max-width: none;
    //    max-height: none;
    //  }
    //}
    .exam-img {
      &.old {
        background: var(--var-gradient-lesson);
      }
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .exam-wave {
        width: 40px;
        height: 10px;
      }
      width: 224px;
      height: 128px;
      background: var(--var-gradient-lesson);
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
    }
    .exam-txt {
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        color: var(--text-tertiary-color);
      }
      a,
      span {
        cursor: pointer;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        text-decoration-line: underline;
        color: var(--text-tertiary-color);
        &:hover {
          text-decoration-line: underline;
          color: var(--text-primary-color);
        }
      }
    }
  }
}
</style>
